<template>
  <div v-if="rgConfig" responsible-gaming>
    <PageHeader :site-map="siteMap" :root-path="rootPath" :baseLang="baseLang" :hasNotification="hasNotification" @openNoti="openNotification" />
    <ResponsibleHeader v-if="isShowSubHeader" />
    <div class="page-content">
      <router-view v-if="rgLoad" :sitemap="siteMap" :config="rgConfig" :structure="structure" />
    </div>
    <div>
      <div class="nl-link">
        <a target="_blank" href="https://cruksregister.nl/" v-if="isFooterLink">{{ $t('footer.footerLink') }}</a>
      </div>
      <GpFooter />
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { sleep } from '@shared/utils/commonUtils.mjs';
import { toFullDateT } from '@/utils/dateTimeUtil';
import { initSalesforce } from '@/plugins/salesforce';
import GamingSiteMap, { routeNames } from '@/constants/model/responsible-gaming';
import PageHeader from '@/views/components/gg-pass/PageHeader.vue';
import ResponsibleReadyModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleReadyModal.vue';
import Specific from '@shared/types/Specific';
import { siteIds } from '@/constants/base/siteMap';
import GpMessageModal from '@/views/components/gg-pass/modal/GpMessageModal.vue';
import GpFooter from '@/views/components/gg-pass/GpFooter.vue';
import { useLugasSiteIds, excludeSkipList } from '@/constants/base/responsible-gaming';
import ResponsibleHeader from '@/views/components/pages/responsible-gaming/template/common/ResponsibleHeader.vue';

export default {
  name: 'ResponsibleGaming',
  components: { ResponsibleHeader, GpFooter, PageHeader },
  data() {
    return {
      load: false,
      rgLoad: false,
      siteMap: null,
      rgConfig: Specific,
      hasNotification: false,
      authError: false,
      structure: {
        action: null,
        config: null,
        redirectUrl: null,
        title: null,
        desc: null,
        back: null,
        result: null,
        backRoute: null,
        showHistory: false,
        buttonName: 'ok',
        redirectUri: null,
        showSubHeader: true,

        current: Specific,
        isConfigReset: true,

        isSelfExclusion: false,
        exclusionEndDate: null,
      },
    };
  },
  computed: {
    config: state('config', 'info'),
    site: state('env', 'site'),
    isMobile: state('browser', 'mobile'),
    env: state('env', 'env'),
    npOnly: state('env', 'npOnly'),
    brand: state('env', 'brand'),
    theme: state('env', 'theme'),
    siteInfo: state('env', 'siteInfo'),
    userInfo: state('user', 'userInfo'),
    countryCode: state('user', 'countryCode'),
    token: state('user', 'token'),
    baseLang: state('env', 'baseLang'),
    isEnabledLugas() {
      return useLugasSiteIds.indexOf(this.site) >= 0;
    },
    isSalesforce() {
      return [siteIds.GGPNL, siteIds.GGPRO].includes(this.site);
    },
    isFooterLink() {
      return [siteIds.GGPNL].includes(this.site);
    },
    isShowSubHeader() {
      return this.site !== siteIds.TWOACE;
    },
    rootPath() {
      if (this.site === siteIds.TWOACE) return routeNames.RgTwoAceLossLimit;
      else return 'ResponsibleGaming';
    },
    excludeSkipSite() {
      return excludeSkipList.includes(this.site);
    }
  },
  watch: {
    '$route.name': 'updateRoute'
  },
  methods: {
    async initialize() {
      if (this.site === siteIds.TWOACE) {
        await this.replaceRouteName(routeNames.RgTwoAceLossLimit);
      }
      this.rgLoad = false;
      await this.rgInitialize(true);
      const result = await this.$rg.currencyInitialize();
      if (!result) return;
      if (this.isEnabledLugas) await this.lugasInitialize();

      this.rgLoad = true;
      if (this.isSalesforce) initSalesforce(this.site, this.baseLang);

      const { redirectUrl } = this.$route.query;
      if (redirectUrl) {
        this.structure.redirectUrl = `${redirectUrl}?token=${this.token}`;
      }

      await this.setNotification();
      await this.openReadyModal();

      this.load = true;
    },
    async rgInitialize(init = false) {
      if (!init) this.rgLoad = false;
      const r = await this.$services.account.getRgConfig(true);
      this.structure.isConfigReset = false;
      if (r.error) {
        const replace = this.$rg.createErrorProxy(r);
        if (replace) {
          this.replaceRouteName(replace.path, replace.params, replace.query);
          return;
        }
      }
      if (this.excludeSkipSite) await this.setSelfExclusion();
      this.rgConfig = {
        ...this.rgConfig,
        ...r,
      };
      if (!init) this.rgLoad = true;
    },

    async lugasInitialize() {
      const r = await this.$services.account.getDepositLimit();
      let EnableExternalMonthlyLimit = typeof r.CurrentExternalMonthlyDepositLimit === 'number';
      this.rgConfig = {
        ...this.rgConfig,
        IsEnableExternalMonthlyLimit: EnableExternalMonthlyLimit,
        HasPendingExternalMonthlyLimit: false,
        ExternalMonthlyLimitVerifyFreeze: false,
      };
    },
    async setSelfExclusion() {
      const r = await this.$rg.getSelfExclusion();
      const isSelfExclusion = r.CurrentSelfExclusionPeriodType.toLowerCase() !== 'none';
      if (!isSelfExclusion) return;
      const locale = this.$i18n('locale');
      this.structure.isSelfExclusion = isSelfExclusion;
      this.structure.exclusionEndDate = toFullDateT(r.ExclusionEndAt, locale);
    },

    async updateRoute() {
      if (this.site === siteIds.TWOACE && this.$route.name !== routeNames.RgTwoAceLossLimit) {
        await this.replaceRouteName(routeNames.RgTwoAceLossLimit);
        return;
      }
      await this.rgInitialize();
    },
    async openReadyModal() {
      const config = await this.$services.base.getGamingConfigReady();
      if (config.error) return;
      const isReadyOpen = Object.keys(config).some(key => config[key]);
      if (isReadyOpen) this.$modal(ResponsibleReadyModal, config);
    },
    async setNotification() {
      if (this.authError) return;
      const result = await this.$services.notice.getNotification();
      const { IsShowAgentNotification, IsUpdatedNotification, AgentNotification } = result;
      this.notification = AgentNotification;
      this.hasNotification = IsShowAgentNotification;
      if (AgentNotification && IsUpdatedNotification) {
        this.openNotification();
      }
      return true;
    },
    openNotification() {
      this.$modal(GpMessageModal, { title: this.$t('notificationBoard'), 'message': this.notification });
    },

  },
  async mounted() {
    if (this.site === siteIds.GGVEGAS) {
      await this.replaceRouteName('NotProvide');
      return;
    }

    this.siteMap = GamingSiteMap();
    this.$store.commit('env/setTheme', 'white');
    await sleep(50);
    await this.initialize();
  },
  beforeDestroy() {
    this.$store.commit('env/setTheme', 'dark');
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[responsible-gaming] { .rel(); .w(100%); .min-h(var(--innerHeight)); .flex(); .flex-dc(); .bgc(@gp-bgc-light-gray); // background: linear-gradient(180deg, #EBEBEF 14.86%, #F7F7F8 100%);
  // .page-holder { .ph(20); .pb(30); flex: 1; }
  .page-content { .flex(); .flex-dc(); .p(0, 0, 30, 0); flex: 1; .w(100%); .max-w(720); margin: auto; }
  .nl-link {.pl(20); .mb(10); .c(@c-link)}
  footer { .p(16, 20); .bgc(@c-b01); .c(@c-b07); .fs(12, 16); .lb(0, 0); .w(100%); }
}
[drop-list] { box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1); border-bottom-left-radius: 16px; border-bottom-right-radius: 16px;
  > .list-holder > .list-box { border: none; .pl(0); .pr(0);
    > .item {
      &:hover {.bgc(#F3FCF5); .c(black);}
      &.active {background: #E6F3E8; .c(black);}
    }
  }
}
</style>
